import http from '@/utils/axiosInterceptors'
import router from "@/router/routes";

const API_URL = process.env.VUE_APP_API_URL; // 백엔드 API URL

export const state = {
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    isLoggedIn: !!localStorage.getItem('accessToken'),
    // 추가적으로 사용자 정보 등을 상태로 관리할 수 있습니다.
    currentUser: {
        userName: '',
        profile: '',
        email: '',
        role: '',
    }
}

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.isLoggedIn
    },
    isUserinfo(state) {
        return !!state.isLoggedIn && !state.currentUser.email
    },
}

export const mutations = {
    SET_ACCESS_TOKEN(state, accessToken) {
        state.accessToken = accessToken;
        localStorage.setItem('accessToken', accessToken);
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
        state.refreshToken = refreshToken;
        localStorage.setItem('refreshToken', refreshToken);
    },
    SET_LOGOUT(state) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        state.accessToken = null;
        state.refreshToken = null;
        state.isLoggedIn = null;
    },
    SET_LOGIN_STATUS(state, status) {
        state.isLoggedIn = status;
    },
    SET_USER_INFO(state, user) {
        state.currentUser.userName = user.username
        state.currentUser.profile = user.profile
        state.currentUser.email = user.email
        state.currentUser.role = user.role
    }
}

export const actions = {
    async loginUser({ commit }, { accessToken, refreshToken }) {
        commit('SET_ACCESS_TOKEN', accessToken);
        commit('SET_REFRESH_TOKEN', refreshToken);
        commit('SET_LOGIN_STATUS', !!localStorage.getItem('accessToken'));
        // 추가적으로 사용자 정보를 가져와 상태를 업데이트하는 로직을 여기에 추가할 수 있습니다.
        // commit('SET_USER_INFO', user);
    },
    async getUserInfo({ commit }) {
        try {
            const response = await http.get(`${API_URL}/api/auth/userinfo`);
            commit('SET_USER_INFO', response.data);
        } catch (error) {
            console.error('Error refreshing access token:', error);
            commit('SET_LOGOUT');
            router.push({ name: 'login', query: { error: 'getUserInfoFailed' } });
            return Promise.reject();
        }
    },
    async refreshAccessToken({ commit, state }) {
        try {
            const response = await http.post(`${API_URL}/auth/refresh`, { refreshToken: state.refreshToken });
            const { accessToken } = response.data;
            commit('SET_ACCESS_TOKEN', accessToken);
            return accessToken;
        } catch (error) {
            console.error('Error refreshing access token:', error);
            commit('SET_LOGOUT');
        }
    },
    async logoutUser({ commit }) {
        console.log("logout");
        // 로그아웃 처리 후 추가적인 로직을 구현할 수 있습니다.
        await http.get(`${API_URL}/api/logout`).then().finally(
            commit('SET_LOGOUT'),
            location.reload(true)
        );
    },
    initiateKakaoLogin() {
        window.location.href = `${API_URL}/oauth2/authorization/kakao`;
    }
}
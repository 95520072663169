import store from "@/state/store";

export default [
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/dashboards/sales/index"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/pages/account/socialLogin.vue"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "home" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/boardlist",
    name: "boardlist",
    component: () => import("@/views/pages/tables/advanced.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/postdetail/:postId",
    name: "PostDetail",
    component: () => import("@/views/pages/tables/PostDetail.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/createPost",
    name: "CreatePost",
    component: () => import("@/views/pages/tables/CreatePost.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/auth/success",
    name: "AuthSuccess",
    component: () => import("@/components/AuthSuccess.vue"),
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/pages/account/register"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/error/404-basic",
    name: "error-404-basic",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/errors/404-basic"),
  },
  {
    path: "/error/404-cover",
    name: "error-404-cover",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/errors/404-cover"),
  },
  {
    path: "/error/500-basic",
    name: "error-500-basic",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/errors/500-basic"),
  },
  {
    path: "/error/500-cover",
    name: "error-500-cover",
    meta: {
      authRequired: true,
    },
    component: () => import("@/views/pages/errors/500-cover"),
  },
];

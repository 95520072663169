import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";
import { createApp } from "vue";
import App from "@/App.vue"; // 가정한 주 컴포넌트 파일 경로입니다
import store from "@/state/store";

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { top: 0 };
    },
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    if (process.env.VUE_APP_DEFAULT_AUTH === "oauth") {
        if (!store.state.auth) { // auth 모듈이 정의되어 있는지 확인합니다.
            console.error('Auth module is not defined in the store');
            next({ name: 'login' });
            return;
        }

        // 로그인 여부 확인
        const loggedIn = store.getters["auth/loggedIn"];
        if (routeTo.matched.some(route => route.meta.authRequired) && !loggedIn) {
            next({ name: "login", query: { redirectFrom: routeTo.fullPath } });
        } else {
            if (store.getters["auth/isUserinfo"]) {
                store.dispatch('auth/getUserInfo').then(() => {});
            }
            next();
        }
    }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args);
                            reject(new Error("Redirected"));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    // Otherwise, continue resolving the route.
                    resolve();
                }
            });
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return;
    }

    // If we reach this point, continue resolving the route.
    next();
});

// createApp을 사용하여 Vue 인스턴스를 생성하고 라우터와 스토어를 사용합니다.
const app = createApp(App);
app.use(router);
app.use(store);
// 여기에서 Vue Meta 설정을 추가합니다. Vue Meta 3 버전이 필요합니다.
// app.use(VueMeta, { keyName: "page" });

app.mount("#app");

export default router;
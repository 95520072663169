export const state = {
    todos: [],
};

export const getters = {
    todos: state => state.todos,
};

export const mutations = {
    setTodos(state, newValue) {
        state.todos = newValue
    },
};

export const actions = {
    fetchTodos({ commit }, { newValue }) {
        commit('setTodos', newValue)
    },
};

// axiosInterceptors.js
import axios from 'axios';
import router from "@/router/routes";
import store from "@/state/store";

const http = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

http.interceptors.request.use(config => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

http.interceptors.response.use((response) => {
    // 응답 헤더에서 새로운 액세스 토큰 확인
    const newAccessToken = response.headers['authorization'] || response.headers['Authorization'];
    if (newAccessToken) {
        // 'Bearer ' 접두어 제거 후 토큰만 추출
        const token = newAccessToken.split(' ')[1];

        // 새 액세스 토큰을 로컬 스토리지에 저장
        localStorage.setItem('accessToken', token);

        // 모든 요청의 기본 헤더를 새 토큰으로 업데이트
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return response;
}, (error) => {
    if (error.response && error.response.status === 401) {
        // 401 오류 처리 로직
        // Vuex 스토어를 통해 로그아웃 처리
        store.dispatch('logoutUser').then(() => {
            // 로그인 페이지로 리디렉트
            router.push('/login');
        });
        // 하위 컴포넌트로 에러를 전파하지 않음
        return new Promise(() => {});
    }
    // 오류 응답을 처리합니다.
    return Promise.reject(error);
});

export default http;